<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">What do the following labels indicate about a particular substance?</p>

      <v-row>
        <p class="mb-6">
          <v-img style="max-width: 240px" :src="image1Name" />
        </p>
        <p class="pt-15 mb-0">
          <v-select
            v-model="inputs.studentAnswer1"
            class="d-sm-inline-block ml-3 mr-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </p>
      </v-row>

      <v-row>
        <p class="mb-6">
          <v-img style="max-width: 240px" :src="image2Name" />
        </p>
        <p class="pt-15 mb-0">
          <v-select
            v-model="inputs.studentAnswer2"
            class="d-sm-inline-block ml-3 mr-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </p>
      </v-row>

      <v-row>
        <p class="mb-6">
          <v-img style="max-width: 240px" :src="image3Name" />
        </p>
        <p class="pt-15 mb-0">
          <v-select
            v-model="inputs.studentAnswer3"
            class="d-sm-inline-block ml-3 mr-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </p>
      </v-row>

      <v-row>
        <p class="mb-6">
          <v-img style="max-width: 240px" :src="image4Name" />
        </p>
        <p class="pt-15 mb-0">
          <v-select
            v-model="inputs.studentAnswer4"
            class="d-sm-inline-block ml-3 mr-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </p>
      </v-row>

      <v-row>
        <p class="mb-6">
          <v-img style="max-width: 240px" :src="image5Name" />
        </p>
        <p class="pt-15 mb-0">
          <v-select
            v-model="inputs.studentAnswer5"
            class="d-sm-inline-block ml-3 mr-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </p>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'uci51LCPrelab1Q5',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer1: null,
        studentAnswer2: null,
        studentAnswer3: null,
        studentAnswer4: null,
        studentAnswer5: null,
      },
      items: [
        {text: 'Corrosive', value: 'corrosive'},
        {text: 'Environmental Hazard', value: 'envHaz'},
        {text: 'Explosive', value: 'explosive'},
        {text: 'Flammable', value: 'flammable'},
        {text: 'Gas Cylinder', value: 'gasCyl'},
        {text: 'Health Hazard', value: 'healthHaz'},
        {text: 'Immediate Irritant', value: 'immIrritant'},
        {text: 'Oxidizer', value: 'oxidizer'},
        {text: 'Toxic', value: 'toxic'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value ?? 1;
    },
    image1Name() {
      if (this.versionNumber === 1) {
        return '/img/assignments/ghsSymbol2.jpg';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/ghsSymbol1.jpg';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/ghsSymbol5.jpg';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/ghsSymbol7.jpg';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/ghsSymbol8.jpg';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/ghsSymbol8.jpg';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/ghsSymbol8.jpg';
      } else {
        return 'error';
      }
    },
    image2Name() {
      if (this.versionNumber === 1) {
        return '/img/assignments/ghsSymbol9.jpg';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/ghsSymbol5.jpg';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/ghsSymbol4.jpg';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/ghsSymbol5.jpg';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/ghsSymbol7.jpg';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/ghsSymbol7.jpg';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/ghsSymbol6.jpg';
      } else {
        return 'error';
      }
    },
    image3Name() {
      if (this.versionNumber === 1) {
        return '/img/assignments/ghsSymbol1.jpg';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/ghsSymbol4.jpg';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/ghsSymbol6.jpg';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/ghsSymbol2.jpg';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/ghsSymbol6.jpg';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/ghsSymbol9.jpg';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/ghsSymbol7.jpg';
      } else {
        return 'error';
      }
    },
    image4Name() {
      if (this.versionNumber === 1) {
        return '/img/assignments/ghsSymbol3.jpg';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/ghsSymbol3.jpg';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/ghsSymbol3.jpg';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/ghsSymbol3.jpg';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/ghsSymbol2.jpg';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/ghsSymbol3.jpg';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/ghsSymbol5.jpg';
      } else {
        return 'error';
      }
    },
    image5Name() {
      if (this.versionNumber === 1) {
        return '/img/assignments/ghsSymbol4.jpg';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/ghsSymbol7.jpg';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/ghsSymbol2.jpg';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/ghsSymbol4.jpg';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/ghsSymbol9.jpg';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/ghsSymbol6.jpg';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/ghsSymbol9.jpg';
      } else {
        return 'error';
      }
    },
  },
};
</script>
<style scoped></style>
